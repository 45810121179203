import React from 'react';
import { Icon, LinkButton } from 'arkade/components';
import './splash-view.scss';

const buyLink = 'https://store.cdbaby.com/cd/anniesciolla';

const SplashView = () => (
    <div className="splash-view">
        <div className="splash-content">
            <img alt="Annie Sciolla logo" src="/assets/logo-full-black.png" className="logo-symbol" />
            <div className="album-wrapper">
                <a href={buyLink} target="_blank" className="album-image-link">
                    <img
                        src="/assets/soul-full-snapshot.png"
                        alt="Album cover of 'Soul Full Snapshot to Joni' by Annie Sciolla"
                        className="album-cover"
                    />
                </a>
                <div className="album-info-box">
                    <b>Get Annie’s all-new solo album</b>
                    <h3 className="text-primary"><em>“Soul Full Snapshot to Joni”</em></h3>
                    <LinkButton kind="muted" href={buyLink}>
                        Available Now at <b> CDBaby.com &nbsp; <Icon fa="chevron-right" /></b>
                    </LinkButton>
                    <p>Also available on all major streaming services.</p>

                    <br/>
                    <h5 className="text-subtle">New Website coming October 2019.</h5>
                    <p>Site & Content &copy; 1995 - 2019 <a href="mailto:sciollajazz@gmail.com">Annie Sciolla</a>.</p>
                </div>
            </div>
        </div>
    </div>
);

export default SplashView;
